const TYPENAME = 'Auth';

const defaults = {
  auth: {
    __typename: TYPENAME,
    isAuthenticated: false,
    accessToken: null,
    accessTokenExp: null,
    role: null,
    user: null,
    refreshToken: null,
    type: null,
  },
};

export default {
  defaults,
  resolvers: {
    Mutation: {
      loginUser(obj, args, { cache }) {
        const {
          accessToken,
          expiresIn,
          refreshToken,
          user,
          type,
        } = args.accessToken;

        if (window.localStorage) {
          window.localStorage.setItem('access_token', accessToken);
          window.localStorage.setItem('access_token_exp', expiresIn);
          window.localStorage.setItem('refresh_token', refreshToken);
        }

        const data = {
          auth: {
            __typename: TYPENAME,
            accessToken,
            refreshToken,
            user,
            role: (user && user.role) || '',
            isAuthenticated: true,
            accessTokenExp: expiresIn,
            type,
          },
        };

        cache.writeData({ data });
        return null;
      },
      logoutUser(obj, args, { cache }) {
        if (window.localStorage) {
          window.localStorage.removeItem('access_token');
          window.localStorage.removeItem('access_token_exp');
          window.localStorage.removeItem('refresh_token');
        }

        cache.writeData({ data: defaults });
        return null;
      },
    },
  },
};
