import { ApolloClient } from 'apollo-client';
import { from } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { withClientState } from 'apollo-link-state';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import resolvers from './resolvers';
import introspectionQueryResultData from './fragmentTypes.json';

export default () => {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  const cache = new InMemoryCache({
    addTypename: true,
    logger: console.log,
    loggerEnabled: true,
    fragmentMatcher,
  });

  const authLink = setContext((_, { headers }) => {
    const token = window.localStorage
      ? window.localStorage.getItem('access_token')
      : '';
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const onErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (process.env.NODE_ENV === 'development' && graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, statusCode }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );

        if (statusCode === 401) {
          // TODO logout via client state mutationn
        }
      });
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const stateLink = withClientState({
    cache,
    ...resolvers,
  });

  const httpLink = createUploadLink({
    uri: process.env.REACT_APP_AF_GRAPHQL_API,
  });

  return new ApolloClient({
    link: from([onErrorLink, authLink, stateLink, httpLink]),
    cache,
    connectToDevTools: true,
  });
};
